import React from "react"
import { Amplify } from "aws-amplify"
import { Authenticator } from "@aws-amplify/ui-react"
import awsconfig from "../aws-exports"
import { navigate } from "gatsby"

import "@aws-amplify/ui-react/styles.css"
import "../styles/global.scss"

Amplify.configure(awsconfig)

export default function SecretRegister() {
  return (
    <Authenticator
      className="classroom-login secret-register"
      initialState="signUp"
    >
      {() => <>{navigate("/classroom")}</>}
    </Authenticator>
  )
}
